import React from "react"

export const GitPlatforms = ({ width = 270, height = 72, ...rest }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 270 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g opacity="0.7" clipPath="url(#GitPlatforms-clip0)">
      <path
        d="M36 6C19.44 6 6 19.44 6 36C6 49.26 14.61 60.51 26.52 64.5C28.02 64.74 28.5 63.81 28.5 63C28.5 62.31 28.5 60.42 28.5 57.93C20.19 59.73 18.42 53.91 18.42 53.91C17.04 50.43 15.09 49.5 15.09 49.5C12.36 47.64 15.3 47.7 15.3 47.7C18.3 47.91 19.89 50.79 19.89 50.79C22.5 55.35 26.91 54 28.62 53.28C28.89 51.33 29.67 50.01 30.51 49.26C23.85 48.51 16.86 45.93 16.86 34.5C16.86 31.17 18 28.5 19.95 26.37C19.65 25.62 18.6 22.5 20.25 18.45C20.25 18.45 22.77 17.64 28.5 21.51C30.87 20.85 33.45 20.52 36 20.52C38.55 20.52 41.13 20.85 43.5 21.51C49.23 17.64 51.75 18.45 51.75 18.45C53.4 22.5 52.35 25.62 52.05 26.37C54 28.5 55.14 31.17 55.14 34.5C55.14 45.96 48.12 48.48 41.43 49.23C42.51 50.16 43.5 51.99 43.5 54.78C43.5 58.8 43.5 62.04 43.5 63C43.5 63.81 43.98 64.77 45.51 64.5C57.42 60.48 66 49.26 66 36C66 19.44 52.56 6 36 6Z"
        fill="black"
      />
      <g clipPath="url(#clip1)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M135.41 64.8935L146.962 29.3398H123.857L135.41 64.8935Z"
          fill="#E24329"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M135.41 64.8935L123.858 29.3398H107.668L135.41 64.8935Z"
          fill="#FC6D26"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M107.668 29.3398L104.157 40.1443C103.837 41.1297 104.188 42.2093 105.026 42.8183L135.41 64.8935L107.668 29.3398Z"
          fill="#FCA326"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M107.668 29.3416H123.858L116.9 7.92795C116.542 6.82595 114.983 6.82625 114.626 7.92795L107.668 29.3416Z"
          fill="#E24329"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M135.41 64.8935L146.962 29.3398H163.152L135.41 64.8935Z"
          fill="#FC6D26"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M163.152 29.3398L166.663 40.1443C166.983 41.1297 166.632 42.2093 165.794 42.8183L135.41 64.8935L163.152 29.3398Z"
          fill="#FCA326"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M163.152 29.3416H146.962L153.92 7.92795C154.278 6.82595 155.837 6.82625 156.194 7.92795L163.152 29.3416Z"
          fill="#E24329"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M217.153 19.8009C216.987 19.7987 216.822 19.833 216.671 19.9012C216.519 19.9694 216.385 20.0699 216.276 20.1957C216.168 20.3215 216.088 20.4695 216.043 20.6294C215.998 20.7892 215.988 20.957 216.015 21.1209L220.845 50.4467C220.906 50.8055 221.09 51.1317 221.367 51.3679C221.644 51.6041 221.995 51.7353 222.359 51.7383H245.534C245.807 51.7418 246.072 51.6472 246.28 51.4718C246.489 51.2963 246.628 51.0517 246.672 50.7824L251.503 21.1266C251.529 20.9627 251.52 20.7949 251.474 20.6351C251.429 20.4752 251.35 20.3272 251.241 20.2014C251.133 20.0756 250.998 19.9751 250.847 19.9069C250.695 19.8387 250.531 19.8044 250.365 19.8066L217.153 19.8009ZM237.494 40.9958H230.097L228.094 30.532H239.286L237.494 40.9958Z"
          fill="#2684FF"
        />
        <path
          d="M249.966 30.5312H239.286L237.494 40.995H230.097L221.363 51.362C221.64 51.6014 221.993 51.7345 222.359 51.7375H245.54C245.812 51.741 246.077 51.6464 246.286 51.471C246.495 51.2955 246.634 51.0509 246.678 50.7816L249.966 30.5312Z"
          fill="url(#paint0_linear)"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="252.436"
        y1="33.4615"
        x2="234.786"
        y2="47.2368"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.18" stopColor="#0052CC" />
        <stop offset="1" stopColor="#2684FF" />
      </linearGradient>
      <clipPath id="GitPlatforms-clip0">
        <rect width="269.938" height="72" fill="white" />
      </clipPath>
      <clipPath id="clip1">
        <rect
          width="62.7835"
          height="58"
          fill="white"
          transform="translate(104 7)"
        />
      </clipPath>
    </defs>
  </svg>
)
